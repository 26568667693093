<template>
  <v-container dark id="dashboard" fluid tag="section">
    <v-skeleton-loader v-if="firstLoad" :loading="loading"
      type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"></v-skeleton-loader>

    <div v-else>
      <v-row>
        <v-col cols="12">
          <stats-panel :data="stats"></stats-panel>
        </v-col>
      </v-row>

      <v-row v-for="(option, i) in optionsLst" :key="i">
        <v-col cols="12">
          <pnl-chart :options="option" :initOptions="initOptions"></pnl-chart>
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col cols="12">
          <coin-num-chart :options="coinNumOptions" :initOptions="initOptions"></coin-num-chart>
        </v-col>
      </v-row> -->
      
    </div>
  </v-container>
</template>

<script>
import { getStats, getHistoryBalances, getHistoryBalancesNew, myMixins } from "@/api";
import PnlChart from "@/views/dashboard/component/charts/PnlChart";
import CoinNumChart from "@/views/dashboard/component/charts/CoinNumChart";
import StatsPanel from "@/views/dashboard/component/StatsPanel";

import { genPnlOptionsFromResp, genPnlOptionsFromRespSingle, genCoinNumOptionsFromResp } from "@/utils";

import { mm_group, lchan_group, rp_group, group_names, groups, } from '@/api/constants'

export default {
  name: "DashboardDashboard",
  mixins: [myMixins],

  components: {
    PnlChart,
    CoinNumChart,
    StatsPanel,
  },

  data() {
    return {
      timer: -1,
      headers: [],
      stats: [],
      firstLoad: true,
      loading: true,
      resp: {},
      optionsLst: [],
      coinNumOptions: {},
      initOptions: {
        renderer: "canvas",
      },
    };
  },

  watch: {
    resp(val) {

      this.optionsLst = []

      group_names.forEach((group_name) => {
        const opt = genPnlOptionsFromRespSingle(val, group_name).pnlOptions;
        // debugger
        this.optionsLst.push(opt)
      })

      // this.coinNumOptions = genCoinNumOptionsFromResp(val);
    },
  },

  destroyed() {
    clearInterval(this.timer);
  },

  mounted: function () {
    this.timer = setInterval(() => {
      this.refresh();
    }, 30000);

    this.refresh();
  },

  methods: {
    refresh() {

      getStats()
        .then((stats) => {
          this.stats = stats.data;
        })
        .catch((reason) => {
          console.error(reason);
          this.logout()
        });

      getHistoryBalancesNew().then((resp) => {
        this.resp = resp;
        if (this.firstLoad) this.firstLoad = false;
        this.loading = false;
      }).catch((reason) => {
        console.error(reason);
        this.logout()
      });
    },
  },
};
</script>
