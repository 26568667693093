<template>
  <v-data-iterator :items="data" hide-default-footer>
    <template v-slot:header>
      <v-toolbar class="mb-2" color="indigo darken-5" dark flat>
        <v-toolbar-title>Strategy Statistics</v-toolbar-title>
      </v-toolbar>
    </template>

    <template v-slot:default="props">
      <v-row>
        <v-col v-for="item in props.items" :key="item.key" cols="12" sm="6" md="6" lg="4">
          <v-card>
            <v-card-title class="subheading font-weight-bold">
              {{ item.gateway_name }} | {{ item.owner }}
            </v-card-title>

            <v-divider></v-divider>

            <v-list dense>
              <v-list-item v-for="(item2, i) in item.statistics" :key="i">
                <v-list-item-content>{{ item2.item_name }}:</v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ fmtItem(item2.value) }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
export default {
  name: "StatsPanel",

  props: {
    data: Array,
  },

  data: () => ({}),

  methods: {
    fmtItem(item) {
      try {
        if (item.hasOwnProperty("type")) {
          switch (item.type) {
            case 'number': {
              return parseFloat(item.value).toFixed(4);
            }
            case 'timestamp': {
              return new Date(parseInt(item.value * 1000)).toLocaleString();
            }
            case 'percentage': {
              break;
            }
            default:
              break;
          }
        }
      } catch (reason) {
        console.error(reason);
      }
      return item;
    },
  },
};
</script>
