const formatter = function (params, tickets, callback) {
    let res = `<b>${new Date(params[0].axisValue).toLocaleString()}</b><br/>`;
    for (let i = 0; i < params.length; i++) {
        // res += `<br/> ${params[i].marker} ${params[i].seriesName}: <strong>${params[i].value[1].toFixed(4)}</strong>`;
        res += `<br/> ${params[i].marker} ${params[i].seriesName}: <strong>${params[i].value[1]}</strong>`;
    }
    return res;
}

const getStart = (len) => {
    let showPoint = 120

    if (len === null || len === undefined || len <= showPoint) {
        len = showPoint
    }

    if (showPoint < len / 5) {
        showPoint = len / 5;
    }

    return parseInt(100 * (1 - showPoint / len))
}

export function genPnlOptionsFromResp(resp) {

    const series = [
        {
            name: "Total_Assets",
            type: "line",
            data: resp.value_total_lst,
        },
        {
            name: "Base_Assets",
            type: "line",
            data: resp.base_value_total_lst,
        },
        {
            name: "PnL",
            type: "line",
            yAxisIndex: 1,
            data: resp.pnl_lst,
        },
    ];

    const pnlOptions = {
        title: {
            text: "Capital Curve",
            textStyle: {
                color: "#ffffff",
            },
        },
        legend: {
            textStyle: {
                color: "#ffffff",
            },
            data: ["Total_Assets", "Base_Assets", "PnL"],
        },
        grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
        },
        // tooltip: {
        //     trigger: "axis",
        //     formatter,
        // },
        toolbox: {
            feature: {
                dataZoom: {
                    show: false,
                    // zoomOnMouseWheel: true, //Whether the wheel triggers zoom
                    // moveOnMouseMove: true, //Mouse wheel triggers scroll
                    // moveOnMouseWheel: true,
                },
            },
        },
        dataZoom: [
            {
                id: 'dataZoomX',
                type: 'slider',
                xAxisIndex: [0],
                filterMode: 'filter',
                start: getStart(resp.pnl_lst.length),
                end: 100,
            },
        ],
        xAxis: {
            axisLabel: {
                show: true,
                textStyle: {
                    color: "#ffffff",
                },
            },
            type: "time",
            boundaryGap: ["0", "10%"],
        },
        yAxis: [{
            name: 'Capital',
            axisLabel: {
                show: true,
                textStyle: {
                    color: "#ffffff",
                },
            },
            type: "value",
        }, {
            name: 'Pnl',
            axisLabel: {
                show: true,
                textStyle: {
                    color: "#ffffff",
                },
            },
            type: "value",
        }],
        series,
    };

    return { pnlOptions };

    }

    export function genPnlOptionsFromRespSingle(resp, group_name) {

        const series = [
            {
                name: "Total",
                type: "line",
                data: resp.value_lst_map[group_name],
            },
            {
                name: "Base",
                type: "line",
                data: resp.base_lst_map[group_name],
            },
            {
                name: "PnL",
                type: "line",
                yAxisIndex: 1,
                data: resp.pnl_lst_map[group_name],
            },
        ];

        const pnlOptions = {
            title: {
                text: group_name + " Capital Curve",
                textStyle: {
                    color: "#ffffff",
                },
            },
            legend: {
                textStyle: {
                    color: "#ffffff",
                },
                data: ["Total", "Base", "PnL"],
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
            },
            tooltip: {
                trigger: "axis",
                formatter,
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        show: false,
                        // zoomOnMouseWheel: true, //Whether the wheel triggers zoom
                        // moveOnMouseMove: true, //Mouse wheel triggers scroll
                        // moveOnMouseWheel: true,
                    },
                },
            },
            dataZoom: [
                {
                    id: 'dataZoomX',
                    type: 'slider',
                    xAxisIndex: [0],
                    filterMode: 'filter',
                    start: getStart(resp.pnl_lst_map[group_name].length),
                    end: 100,
                },
            ],
            xAxis: {
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: "#ffffff",
                    },
                },
                type: "time",
                boundaryGap: ["0", "10%"],
            },
            yAxis: [{
                name: 'Capital',
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: "#ffffff",
                    },
                },
                type: "value",
            }, {
                name: 'PnL',
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: "#ffffff",
                    },
                },
                type: "value",
            }],
            series,
        };

        return { pnlOptions };
    }

    export function genCoinNumOptionsFromResp(resp) {

        const coinNumOptions = {
            title: {
                text: "Coin Count",
                textStyle: {
                    color: "#ffffff",
                },
            },
            tooltip: {
                trigger: "axis",
                formatter,
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        show: false,
                        // zoomOnMouseWheel: true, //Whether the wheel triggers zoom
                        // moveOnMouseMove: true, //Mouse wheel triggers scroll
                        // moveOnMouseWheel: true,
                    },
                },
            },
            dataZoom: [
                {
                    id: 'dataZoomX',
                    type: 'slider',
                    xAxisIndex: [0],
                    filterMode: 'filter',
                    start: getStart(resp.pnl_lst.length),
                    end: 100,
                },
            ],
            legend: {
                textStyle: {
                    color: "#ffffff",
                },
                data: resp.keys,
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        show: true,
                    },
                },
            },
            xAxis: {
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: "#ffffff",
                    },
                },
                type: "time",
                boundaryGap: ["0", "10%"],
            },
            yAxis: {
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: "#ffffff",
                    },
                },
                type: "value",
            },
            series: resp.values,
        };

        return coinNumOptions;
    }
